<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, envie o arquivo de dados a ser importado
    </div>
    <v-row
      id="importacao-upload-files"
      class="mx-auto"
    >
      <v-col cols="12">
        <upload-files
          id="fs-upload-importacao-dados"
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
          dispatcherFile="xml"
        >
        </upload-files>
      </v-col>
      <v-col
        class="px-6 py-0"
        cols="12"
        v-if="uploadedFiles.length"
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ uploadedFiles.length }} arquivos enviados</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="uploadedFile in uploadedFiles"
                :key="uploadedFile.id"
              >
                <td>{{ uploadedFile.name }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectEncoding from '@/components/general/SelectEncoding.vue';
import UploadFiles from '@/components/general/UploadFiles/Index.vue';
import LinkDownloadArquivoModelo from '@/components/dados/importacoes-dados/LinkDownloadArquivoModelo.vue';

export default {
  name: 'SecondTabContent',
  components: {
    SelectEncoding,
    UploadFiles,
    LinkDownloadArquivoModelo
  },
  props: {
    dataImportType: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      allowedFiles: ['zip'],
      uploadedFiles: []
    };
  },
  computed: {
    lastUploadedFile() {
      return this.uploadedFiles[this.uploadedFiles.length - 1];
    }
  },
  methods: {
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
    }
  },
  watch: {
    uploadedFiles() {
      this.$emit(
        'file:uploaded',
        this.uploadedFiles.map((item) => item.id)
      );
    }
  }
};
</script>
