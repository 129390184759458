<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas
    </div>
    <v-row
      id="importacao-titulo"
      class="mx-auto"
    >
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="fs-titulo-importacao-dados"
            label="Título da importação"
            outlined
            :error-messages="errors"
            v-model="titulo"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row
      id="importacao-descricao"
      class="mx-auto"
    >
      <v-col cols="12">
        <v-text-field
          id="fs-descricao-importacao-dados"
          label="Descrição (opcional)"
          outlined
          v-model="descricao"
        />
      </v-col>
    </v-row>
    <v-row
      id="importacao-empresa"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-company
          id="fs-empresa-importacao-dados"
          :type="'outlined'"
          @companySelected="companySelected"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectCompany from '@/components/general/SelectCompany.vue';
import SelectTiposImportacoesDados from '@/components/general/SelectTiposImportacoesDados.vue';

export default {
  name: 'FirstTabContent',
  components: {
    SelectCompany,
    SelectTiposImportacoesDados
  },
  data() {
    return {
      titulo: null,
      descricao: null,
      companyId: null,
      tipoImportacao: null
    };
  },
  watch: {
    titulo() {
      this.emitEventImportUpdated();
    },
    descricao() {
      this.emitEventImportUpdated();
    },
    companyId() {
      this.emitEventImportUpdated();
    },
    tipoImportacao() {
      this.emitEventImportUpdated();
    }
  },
  methods: {
    tipoSelected(tipoImportacao) {
      this.tipoImportacao = tipoImportacao;
    },
    companySelected(company) {
      this.companyId = company.id;
    },
    emitEventImportUpdated() {
      this.$emit('import:updated', {
        titulo: this.titulo,
        descricao: this.descricao,
        companyId: this.companyId
      });
    }
  }
};
</script>
