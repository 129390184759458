<template>
  <v-container
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Nova Importação INDQUAL"
        subtitle="Faça o upload dos arquivos XML a serem importados através de arquivos ZIP."
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content @import:updated="handleTabContentUpdated" />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <second-tab-content @file:uploaded="handleFileUploaded" />
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import ImportacoesDadosIndqualXmlService from '@/services/ImportacoesDadosIndqualXmlService';
import MaterialWizard from '@/components/base/MaterialWizard';
import FirstTabContent from '@/components/dados/importacoes-xml/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/dados/importacoes-xml/tabs/SecondTabContent.vue';

export default {
  components: {
    MaterialWizard,
    FirstTabContent,
    SecondTabContent
  },
  data: () => ({
    tab: 0,
    tabs: ['Informações básicas', 'Upload de arquivo Zip'],
    loading: false,
    importacao: {
      titulo: null,
      descricao: null,
      companyId: null,
      arquivos: []
    }
  }),
  computed: {
    availableSteps() {
      let steps = [];
      if (this.tab1IsValid) {
        steps.push(0);
        steps.push(1);
      }
      if (this.tab2IsValid) {
        steps.push(2);
      }
      return steps;
    },
    tab1IsValid() {
      const { titulo, companyId } = this.importacao;
      return titulo !== null && companyId !== null;
    },
    tab2IsValid() {
      return this.tab1IsValid && this.importacao.arquivos.length;
    },
    disabledBtnFinalizar() {
      if (this.tab === 0) return !this.tab1IsValid;
      if (this.tab === 1) return !this.tab2IsValid;
      return true;
    }
  },
  methods: {
    async next(valid) {
      if (!valid) return;
      if (this.tab === 0 && !this.tab1IsValid) {
        return;
      }
      if (this.tab === 1) {
        if (!this.tab2IsValid) {
          return;
        }
        const sucesso = await this.cadastraNovaImportacao();
        if (!sucesso) {
          return;
        }
        this.$router.push({
          name: 'Importações INDQUAL'
        });
      } else {
        this.tab++;
      }
    },
    handleTabContentUpdated(event) {
      this.importacao = Object.assign(this.importacao, event);
    },
    handleFileUploaded(files) {
      this.importacao.arquivos = files;
    },
    async cadastraNovaImportacao() {
      let sucesso = false;
      this.loading = true;
      try {
        const { titulo, descricao, arquivos, companyId } = this.importacao;
        const importacao = {
          titulo,
          descricao,
          arquivos,
          company_id: companyId
        };
        sucesso = await ImportacoesDadosIndqualXmlService.save(importacao);
        this.$toast.success('Importação INDQUAL cadastrada com sucesso.', '', {
          position: 'topRight'
        });
      } catch (e) {
        this.$toast.error(
          'Ocorreu um erro ao cadastrar a importação INDQUAL.',
          '',
          {
            position: 'topRight'
          }
        );
        console.error(e);
      } finally {
        this.loading = false;
      }
      return sucesso;
    }
  }
};
</script>
